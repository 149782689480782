@import url('https://fonts.googleapis.com/css2?family=Sono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');


.h-all{
    display: flex;
    background: #F8FAFC;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.h-no-team{
    width: 100%;
    font-family: "Sono", sans-serif;
    margin-top: 15px;
    text-align: center;
    font-size: 20px;
}

.h-add{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 10px 40px 0px;
    z-index: 100;
    position: fixed;
    font-size: 22px;
}

.h-id-box{
  border-radius: 5px;
  font-size: 18px;
  width: 100px;
  padding: 7px;
  outline: none;
  color: #555555;
  border: 3px solid #AAAAAA;
}

.h-id-box::placeholder{
  color: #AAAAAA;
}

.h-nick-box{
  border-radius: 5px;
  font-size: 18px;
  width: 300px;
  padding: 7px;
  outline: none;
  color: #555555;
  border: 3px solid #AAAAAA;
}

.h-nick-box::placeholder{
  color: #AAAAAA;
}

.h-editor{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.h-title{
    font-family: "Sono", sans-serif;
    font-size: 25px;
    color: #222222;
    margin-bottom: 30px;
}

.h-button-holder{
    display: flex;
    justify-content: space-between;
    margin: 5px;
    margin-top: 40px;
}

.h-button{
    color: #FFFFFF;
    background: #0D6EFD;
    padding: 15px;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 300ms;
    font-family: "Ubuntu", sans-serif;
}

.h-button:hover{
    background: #5296FD;
}

.h-table{
    font-family: "Ubuntu", sans-serif;
    border-collapse: collapse;
}

.h-head{
    font-size: 20px;
    border-bottom: 0.1px solid #555555;
    text-align: left;
}

.h-head div{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.h-head{
  height: 60px;
}

.h-body tr:nth-child(even){
  background-color: #E5E7EB;
}

.h-row div{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.h-row{
  height: 35px;
}